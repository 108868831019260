
<template>
  <div>
    <b-card no-body class="m-0">
      <b-row>
        <b-col md="6" class="d-flex mt-2">
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :clearable="false"
            v-model="per_page"
            class="per-page-selector d-inline-block ml-2 w-25"
            :options="['5', '10', '20', '50', '100']"
            @input="changePagination()"
          />
          <!-- Search -->
        </b-col>
        <b-col class="d-flex d-flex justify-content-end">
          <b-button variant="primary" @click="handleCms()" class="mr-2 mt-2"
            >Add CMS</b-button
          >
        </b-col>
      </b-row>
      <div class="m-2">
        <section id="card-images" class="container1">
          <b-row
            md="12"
            style="align-item: center; justify-content: center; display: flex"
          >
            
            <b-table
              :busy="isLoading"
              ref="refUserListTable"
              class="position-relative"
              :items="data"
              responsive
              :fields="tableColumns"
              primary-key="id"
              :sort-by.sync="sortBy"
              show-empty
              empty-text="No matching records found"
              @sort-changed="onSortChanged"
              
              >
              <!-- :sort-desc.sync="isSortDirDesc" -->
            <template #table-busy>
              <div class="text-center text-secondary my-2">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </template>
              <template #cell(id)="data">
                <span
                  class="text-primary font-weight-bold"
                  style="font-size: 15px"
                >
                  {{ data.index + 1 }}
                </span>
              </template>
              <!-- <template #thead-top="{ fields }">
                <tr>
                  <th v-for="(field, index) in fields" :key="index">
                    <template v-if="field.key == 'Action'"> &nbsp; </template>
                    <template v-else>
                      <b-form-input
                        v-model="columnFilters[field.key]"
                        @input="filterTable()"
                        placeholder="search..."
                      />
                    </template>
                  </th>
                </tr>
              </template> -->

              <template #cell(title)="data">
                <div class="d-flex align-items-center">
                  <span class="mr-1">
                    <span class="mr-1">
                      {{ data.item.title }}
                    </span>
                  </span>
                </div>
              </template>
              <template #cell(link)="data">
                <span class="mr-1">
                  {{ data.item.link }}
                </span>
              </template>
              <template #cell(Action)="data">
                <feather-icon
                  icon="EditIcon"
                  size="22"
                  class="cursor-pointer mr-1"
                  @click="handleEditForm(data.item.id)"
                />
                <feather-icon
                  icon="Trash2Icon"
                  size="22"
                  class="m-1"
                  @click="removeRow(data.item.id)"
                  style="cursor: pointer"
                />
                <feather-icon
                  icon="EyeIcon"
                  size="22"
                  class="m-1"
                  @click="openData(data.item)"
                  style="cursor: pointer"
                />
              </template>
            </b-table>
            <div class="w-100 d-flex justify-content-between p-1">
              <!-- Pagination -->
              <div>
                <span class="text-nowrap">
                  Showing {{ paginationStart }} to {{ paginationEnd }} of
                  {{ paginationTotal }}</span
                >
              </div>
              <b-pagination
                :total-rows="paginationTotal"
                :per-page="per_page"
                v-model="paginationValue"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @input="changePagination()"
              >
                <!-- <div>
                  <button
                    @click="goToPage(paginationValue - 1)"
                    :disabled="paginationValue === 1"
                  >
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </button>
                  <button
                    @click="goToPage(paginationValue + 1)"
                    :disabled="paginationValue * per_page >= paginationTotal"
                  >
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </button>
                </div> -->
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>

            <b-modal
              hide-footer
              top
              no-close-on-backdrop
              size="md"
              v-model="openModel"
            >
              <div class="p-1">
                <b-row>
                  <b-col md="6">
                    <div class="d-flex flex-column m-1 w-100">
                      <strong>Title:</strong> {{ selectedItem.title }}
                    </div>
                  </b-col>
                  <b-col md="6">
                    <div class="d-flex flex-column m-1 w-100">
                      <strong>Page Link:</strong>
                      {{ selectedItem.link }}
                    </div>
                  </b-col>
                  <b-col md="6">
                    <div class="d-flex flex-column m-1 w-100">
                      <strong>Seo Title:</strong>{{ selectedItem.metatitle }}
                    </div>
                  </b-col>
                  <b-col md="6">
                    <div class="d-flex flex-column m-1 w-100">
                      <strong>Meta Description:</strong>{{ selectedItem.metadescription }}
                    </div>
                  </b-col>
                  <b-col md="6">
                    <div class="d-flex flex-column m-1 w-100">
                      <strong>Keyword:</strong>{{ selectedItem.keyword }}
                    </div>
                  </b-col>
                  <b-col md="12">
                    <div class="d-flex flex-column m-1 w-100">
                      <strong>Page Content:</strong
                      >{{ selectedItem.content ? removeHTMLTags(selectedItem.content) : '' }}
                    </div>
                  </b-col>
                  <b-col md="6">
                    <div class="d-flex flex-column m-1 w-100">
                      <strong>Image:</strong>
                      <img :src="selectedItem.metaimage ? selectedItem.metaimage:'No Image' " >
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-3">
                  <b-col>
                    <div class="d-flex justify-content-end">
                      <b-button variant="primary" @click="Close()">
                        Close
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-modal>
          </b-row>
        </section>
      </div>
    </b-card>
  </div>
</template>
<script>
import BootstrapVue from "bootstrap-vue";
import {
  BRow,
  BCol,
  BCardText,
  BCardTitle,
  BPagination,
  BButton,
  BCard,
  BForm,
  BTable,
  BFormFile,
  BFormInput,
  BFormGroup,
  BBadge,
  BSpinner,
  BImg,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import axios from "../../../components/axios";
import { required } from "@validations";
import _ from "lodash";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    BRow,
    BCol,
    vSelect,
    BCardText,
    BImg,
    BCardTitle,
    BButton,
    BPagination,
    BCard,
    BForm,
    BTable,
    BFormFile,
    BFormInput,
    BFormGroup,
    BBadge,
    ValidationProvider,
    BSpinner,
    ValidationObserver,
    quillEditor,
  },
  data() {
    return {
      snowOption: {
        theme: "snow",
      },
      flag: false,
      tableColumns: [
        { key: "title", label: "Title", sortable: true },
        { key: "link", label: "Page Link", sortable: true },
        { key: "Action", label: "Action" },
      ],
      isSortDirDesc: true,
      required: "",
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      selectedId: null,
      sortBy: "",
      orderBy: true ? "ASC" : "DESC",
      clickedRowData: null,
      isSideDataLoading: false,
      openModel: false,
      staticUsers: [],
      id: 0,
      isLoading: false,
      selectedItem: {},
      ifEdit: false,
      paginationValue: 1,
      paginationTotal: 0,
      per_page: 10,
      paginationStart: 0,
      paginationEnd: 0,
      image: "",
      columnFilters: {},
      params: {},
      data: [],

    };
  },
  mounted() {
    this.getCms();
  },
  methods: {
    onSortChanged(e) {
      this.sortBy = e.sortBy;
      this.orderBy = e.sortDesc ? "DESC" : "ASC";
      // console.log(this.sortBy)
      // console.log(this.orderBy)
      this.getCms();
    },
    removeHTMLTags(content) {
    return content.replace(/<[^>]+>/g, '');
  },
    filterTable: _.debounce(function () {
      this.params = {};
      for (let key in this.columnFilters) {
        if (
          this.columnFilters[key] &&
          typeof this.columnFilters[key] == "object"
        ) {
          this.params[key] = this.columnFilters[key]
            .map((inner) => inner.id)
            .join(",");
        } else {
          this.params[key] = this.columnFilters[key];
        }
      }
      this.params.page = this.paginationValue;
      this.getCms();
    }, 1000),
   
    changePagination() {
      this.getCms();
    },
 
    goToPage(page) {
      if (
        page >= 1 &&
        page <= Math.ceil(this.paginationTotal / this.per_page)
      ) {
        this.paginationValue = page;
        this.getCms();
      }
    },
    handleEditForm(itemId) {
      this.$router.push({ name: 'edit-cms', params: { id: itemId } });
    },
    async getCms() {
      this.isLoading = true;
      let params = {};
      params.page = this.paginationValue;
      params.per_page = this.per_page;
      if (this.search) {
        params.search = this.search.id ? this.search.id : this.search;
        params.type = this.searchTerm.value;
      }
      if (this.sortBy) {
        params.sortBy = this.sortBy;
        params.orderBy = this.orderBy;
      }
      await axios({
        method: "GET",
        url: `${this.baseApi}cmspage/get`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        params: params,
      })
        .then((json) => {
          this.data = json?.data.data.Cmspage.data;
          this.paginationTotal = json?.data.data.Cmspage.total;
          this.paginationStart = json?.data.data.Cmspage.current_page;
          this.paginationEnd = json?.data.data.Cmspage.per_page;
          this.isLoading = false;
        })
        .catch((error) => console.log(error, "error"))
        .finally(() => {
          this.isLoading = false;
        });
    },

    openData(data) {
      this.openModel = true;
      this.selectedItem = data;
    },
    
    async removeRow(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then(async (result) => {
          if (result.value) {
            await axios({
              method: "Delete",
              url: `${this.baseApi}cmspage/delete/${id}`,
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.accessToken}`,
              },
            })
              .then(() => {
                  this.$swal({
                    title: "Deleted",
                    text: "Deleted successfully",
                    icon: "success",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                  });
                  this.getCms();
              })
              .catch((error) => {
                console.error("Error deleting user:", error);
              });
          }
        })
        .catch((error) => console.log(error, "error"))
        .finally(() => {});
    },

    handleCms(id) {
      this.selectedId = id;
      this.$router.push({ path: "/cms/addcms" });
    },
    Close() {
      this.$router.push({ path: "/cms"})
      this.openModel = false;
    },
  },
};
</script>
<style lang="scss">
.text-danger {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

 
../../../components/axios../../../components/nav/axios